import eventosApi from "@/api/eventosApi"
// Activar el preloader
export const actLoading = async ({ commit }) => {
    commit('actLoading')
}

// Activar el preloader
export const setLoading = async ({ commit }) => {
    commit('setLoading')
}

//limpiar Eventos
export const clearEventos = async ({ commit }) => {
    commit('clearEventos')
}

//Cargar todos los eventos
export const loadEventos = async ({ commit }) => {
    const { data } = await eventosApi.get('/eventos')
    if (!data) {
        commit('setEventos', [])
        return
    }
    const eventos = data
    localStorage.setItem('eventos', JSON.stringify(eventos));
    commit('setEventos', eventos)
}

//cargar todos los eventos con sus categorias
export const loadEventosCategorias = async ({ commit }) => {
    const { data } = await eventosApi.get('/evento/eventosconcategorias')
    if (!data) { commit('setEventosCategorias', []); return }
    const eventos = data
    localStorage.setItem('eventos', JSON.stringify(eventos));
    commit('setEventosCategorias', eventos)
}

//cargar evento por slug
export const loadEvento = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug}`)
    if (!data.data) {
        commit('setEvento', [])
        return
    }
    const evento = data.data
    commit('setEvento', evento)
}

//cargar la configuracion Logos, Imagenes, Slider
export const configHeader = async ({ commit }) => {
    const { data } = await eventosApi.get('/conf')
    commit('setConfig', data)
}

//carga evento - categorias - atletas
export const direccionarEvento = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug}/general`)
    if (!data) { commit('setEventoCategoriasAtletas', []); return }
    commit('setEventoCategoriasAtletas', data)
}

//carga evento - categorias - atletas de esa categoria
export const direccionarEventoCategoria = async ({ commit }, scat) => {
    const { data } = await eventosApi.get(`/evento/${scat.slug}/${scat.categoria}/categoria`)
    if (!data) { commit('setDirEventoCategoriasAtletas', []); return }
    commit('setEventoCategoriasAtletas', data)
}
//carga evento - atletas por sexo
export const direccionarEventoSexo = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug.slug}/${slug.sexo}/sexo`)
    if (!data) { commit('setEventoCategoriasAtletasSexo', []); return }
    commit('setEventoCategoriasAtletasSexo', data)
}
//carga evento - atletas por sexo - atletas de esa categoria
export const direccionarEventoCategoriaSexo = async ({ commit }, slug, categoria, sexo) => {
    const { data } = await eventosApi.get(`/evento/${slug.slug}/${slug.categoria}/${slug.sexo}/categoria-y-sexo`)
    if (!data) { commit('setEventoCategoriasAtletasSexo', []); return }
    commit('setEventoCategoriasAtletasSexo', data)
}

//carga evento - Atletas por club

export const direccionarEventoClub = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug['slug']}/${slug['club']}/club`)
    if (!data) { commit('setEventoCategoriasAtletas', []); return }
    commit('setEventoCategoriasAtletas', data)
}

export const SearchName = async ({ commit }, term) => {
    commit('setSearchName', term)
}



//LOCAL

export const mostrarPorCategoriaSexo = async ({ commit }, result) => {
    commit('mostrarAtletasPorCategoriaSexo', result)
}

export const mostrarPorCategoriaDistancia = async ({ commit }, res) => {
    commit('mostrarPorCategoriaDistancia', res)
}