import registerApi from "@/api/registerApi"
import registerApiMail from "@/api/registerApiMail"

export const validarEmail = async ({ commit }, info) => {
    const { data } = await registerApi.post(`/register/registrar-email`, info)
    //return data
}


export const registerAtleta = async ({ commit }) => {

}

export const consultarCarrerasActions = async ({ commit }) => {
    const { data } = await registerApi.get('/consulta/carreras')
    if (!data) {
        commit('consultarCarreras', [])
        return
    }
    commit('consultarCarreras', data)
}

export const consultarCarreraTokenActions = async ({ commit }, token) => {
    const { data } = await registerApi.get(`/consulta/carrera/${token}`)
    if (!data) {
        commit('consultarCarrerasToken', [])
        return
    }
    commit('consultarCarrerasToken', data)
    return data
}

export const consultarUserTokenActions = async ({ commit }, token) => {
    const { data } = await registerApi.get(`/consulta/token-user/${token}`)
    if (data) {
        if (data.estado == '1') {
            commit('consultarUserToken', data)
            return data
        } else {
            return data
        }
    } else {
        return []
    }
}


export const createEmail = async ({ commit }, datos) => {
    if (localStorage.getItem('hast_token')) {
        localStorage.removeItem('hast_token')
    }
    const { carrera_id, email } = datos
    const { data } = await registerApi.post(`/register/registrar-email`, datos)
    /*if (data.resultado) {
        const { dato } = await registerApiMail.get(`/enviar?token=${data.resultado.hast_token}`)
        localStorage.setItem('hast_token', data.resultado.hast_token)
    }*/
    return data
}

export const consultaCodigo = async ({ commit }, codigo) => {
    const { data } = await registerApi.post(`/register/validar-codigo`, codigo)
    return data
}

export const consultaCategorias = async ({ commit }, id) => {
    const { data } = await registerApi.get(`/consulta/categorias/${id}`)
    return data
}

export const consultarToken = async ({ commit }, token) => {
    const { data } = await registerApi.get(`/consulta/token/${token}`)
    return data
}
export const consultarDorsalToken = async ({ commit }, token) => {
    const { data } = await registerApi.get(`/consulta/token-dorsal/${token}`)
    return data
}
export const registrarAtleta = async ({ commit }, datos) => {
    const { data } = await registerApi.post(`/register/registrar`, datos)
    if (data.token) {
        let tkn = ''
        if (datos.tipo == 'preinscripcion') {
            tkn = 'KX1eIxfFjSYcAVYNkFaCQBf6swVp8TgFeh4o0CeN2Q6KB3bG6BLXkoVMZ47xIxopg'
        } else if (datos.tipo == 'updatepreinscripcion') {
            tkn = 'cIPTZZM1HzoOtb2jwdLWRM5CDfzVuEdAnWAkaCJpDu8BIsl8sMlo2jax0BAf0qJnLE'
        } else if (datos.tipo == 'inscripcion') {
            tkn = 'YBig2RgCJtAOCIHfeTyJlmAjTxzB8iPPvHK6oHMQEVke1o8EWPm2pGxbjfwBBWTGMe'
        } else if (datos.tipo == 'comercial') {
            tkn = 'oVMZ4vHK6o7xITyJlmAjTxzB8iPAnWAkPHMQEVke1o8EwBBWTGWPm2pGxbdaCJfejfMe'
        } else {

        }
        const { dato } = await registerApiMail.get(`/registro?token=${data.token}&tipo=${tkn}`)
        localStorage.removeItem('hast_token')
    }
    return data
}
export const actualizarAtleta = async ({ commit }, datos) => {
    const { data } = await registerApi.post(`/register/actualizar/${datos.id}`, datos)
    return data
}


export const atletaseleccionado = ({ commit }, atleta) => {

    commit('selectAtleta', atleta)
}

export const AsignarDorsal = async ({ commit }, dato) => {
    const data = await registerApi.post(`/atleta/${dato.id}/asignar-dorsal`, dato)
    if (data.data.token) {
        const { dato } = await registerApiMail.get(`/dorsal?token=${data.data.token}`)
    }
    return data
}

export const confirmarIncorporarId = async ({ commit }, dato) => {
    const data = await registerApi.get(`/atleta/${dato.id}/restore`)
    /*if (data.data.token) {
        const { dato } = await registerApiMail.get(`/dorsal?token=${data.data.token}`)
    }*/
    commit('restaurarAtleta', dato.id)
    return data
}


export const deleteAtleta = async ({ commit }, id) => {
    const del = await registerApi.delete(`/atleta/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteAtleta', id)
        return del.data
    }
}

export const setCats = ({ commit }, data) => {
    commit('setCategorias', data)
}



//categorias
export const setCategorias = async ({ commit }) => {
    const categorias = await registerApi.get('/categoria/listado')
    commit('mutCategorias', categorias.data)
}

export const deleteCategoria = async ({ commit }, id) => {
    const del = await registerApi.delete(`/categoria/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteCategoria', id)
        return del.data
    }
}

export const updateCategoria = async ({ commit }, categoria) => {

    try {
        const data = await registerApi.put(`/categoria/${id}`, categoria)
        return data
    } catch (error) {
        return error.response.data
    }

}
export const createCategoria = async ({ commit }, categoria) => {
    try {
        const data = await registerApi.post(`/categoria/register`, categoria)
        return data
        //return resp
    } catch (error) {
        return error.response.data
    }
}

//franelas

export const setFranelas = async ({ commit }) => {
    const franelas = await registerApi.get('/franela/listado')
    commit('mutFranelas', franelas.data)
}

export const deleteFranela = async ({ commit }, id) => {
    const del = await registerApi.delete(`/franela/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteFranela', id)
        return del.data
    }
}

export const updateFranela = async ({ commit }, franela) => {
    try {
        const data = await registerApi.put(`/franela/${franela.id}`, franela)
        return data
    } catch (error) {
        return error.response.data
    }

}
export const createFranela = async ({ commit }, franela) => {
    try {
        const data = await registerApi.post(`/franela/register`, franela)
        return data
        //return resp
    } catch (error) {
        return error.response.data
    }
}


// pagos
export const confirmarPagoId = async ({ commit }, datos) => {
    const data = await registerApi.post(`/atleta/${datos.id}/confirmar-pago`)
    if (data) {
        if (data.data.token) {
            let tkn = ''
            if (datos.tipo == 'preinscripcion') {
                tkn = 'KX1eIxfFjSYcAVYNkFaCQBf6swVp8TgFeh4o0CeN2Q6KB3bG6BLXkoVMZ47xIxopg'
            } else if (datos.tipo == 'updatepreinscripcion') {
                tkn = 'cIPTZZM1HzoOtb2jwdLWRM5CDfzVuEdAnWAkaCJpDu8BIsl8sMlo2jax0BAf0qJnLE'
            } else if (datos.tipo == 'inscripcion') {
                tkn = 'YBig2RgCJtAOCIHfeTyJlmAjTxzB8iPPvHK6oHMQEVke1o8EWPm2pGxbjfwBBWTGMe'
            } else if (datos.tipo == 'comercial') {
                tkn = 'oVMZ4vHK6o7xITyJlmAjTxzB8iPAnWAkPHMQEVke1o8EwBBWTGWPm2pGxbdaCJfejfMe'
            } else { }
            const { dato } = await registerApiMail.get(`/registro?token=${data.data.token}&tipo=${tkn}`)
        }
    }
    commit('confirmarPago', datos.id)
    return data
}


export const updatePago = async ({ commit }, pago) => {
    try {
        const data = await registerApi.put(`/pago/${pago[1]}`, pago[0])
        return data
        //return resp
    } catch (error) {
        return error.response.data
    }
}
export const exportar = async ({ commit }, valor) => {
    const hast = localStorage.getItem('hast_token')
    if (hast) {
        const resp = await registerApi.get(`/consulta/export/${valor.id}?token=${hast}&tipo=${valor.tipo}`)
        return resp.data
    } else {
        localStorage.removeItem('hast_token')
    }
}


export const vaciarAction = async ({ commit }) => {
    commit('vaciar')
}





/*
export const createEntry = async ({ commit }, entry) => {
    const { date, text, picture } = entry
    const dataSave = { date, text, picture }
    const { data } = await journalApi.post(`/entry.json`, entry)
    dataSave.id = data.name
    commit('createEntry', dataSave)
    return data.name
}

// Activar el preloader
export const actLoading = async ({ commit }) => {
    commit('actLoading')
}

// Activar el preloader
export const setLoading = async ({ commit }) => {
    commit('setLoading')
}

//limpiar Eventos
export const clearEventos = async ({ commit }) => {
    commit('clearEventos')
}

//Cargar todos los eventos
export const loadEventos = async ({ commit }) => {
    const { data } = await eventosApi.get('/eventos')
    if (!data) {
        commit('setEventos', [])
        return
    }
    const eventos = data
    localStorage.setItem('eventos', JSON.stringify(eventos));
    commit('setEventos', eventos)
}

//cargar todos los eventos con sus categorias
export const loadEventosCategorias = async ({ commit }) => {
    const { data } = await eventosApi.get('/evento/eventosconcategorias')
    if (!data) { commit('setEventosCategorias', []); return }
    const eventos = data
    localStorage.setItem('eventos', JSON.stringify(eventos));
    commit('setEventosCategorias', eventos)
}

//cargar evento por slug
export const loadEvento = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug}`)
    if (!data.data) {
        commit('setEvento', [])
        return
    }
    const evento = data.data
    commit('setEvento', evento)
}

//cargar la configuracion Logos, Imagenes, Slider
export const configHeader = async ({ commit }) => {
    const { data } = await eventosApi.get('/conf')
    commit('setConfig', data)
}

//carga evento - categorias - atletas
export const direccionarEvento = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug}/general`)
    if (!data) { commit('setEventoCategoriasAtletas', []); return }
    commit('setEventoCategoriasAtletas', data)
}

//carga evento - categorias - atletas de esa categoria
export const direccionarEventoCategoria = async ({ commit }, scat) => {
    const { data } = await eventosApi.get(`/evento/${scat.slug}/${scat.categoria}/categoria`)
    if (!data) { commit('setDirEventoCategoriasAtletas', []); return }
    commit('setEventoCategoriasAtletas', data)
}
//carga evento - atletas por sexo
export const direccionarEventoSexo = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug.slug}/${slug.sexo}/sexo`)
    if (!data) { commit('setEventoCategoriasAtletasSexo', []); return }
    commit('setEventoCategoriasAtletasSexo', data)
}
//carga evento - atletas por sexo - atletas de esa categoria
export const direccionarEventoCategoriaSexo = async ({ commit }, slug, categoria, sexo) => {
    const { data } = await eventosApi.get(`/evento/${slug.slug}/${slug.categoria}/${slug.sexo}/categoria-y-sexo`)


    if (!data) { commit('setEventoCategoriasAtletasSexo', []); return }
    commit('setEventoCategoriasAtletasSexo', data)
}

//carga evento - Atletas por club

export const direccionarEventoClub = async ({ commit }, slug) => {
    const { data } = await eventosApi.get(`/evento/${slug['slug']}/${slug['club']}/club`)
    if (!data) { commit('setEventoCategoriasAtletas', []); return }
    commit('setEventoCategoriasAtletas', data)
}

export const SearchName = async ({ commit }, term = '') => {
    commit('setSearchName', term)
}



//LOCAL

export const mostrarPorCategoriaSexo = async ({ commit }, result) => {
    commit('mostrarAtletasPorCategoriaSexo', result)
}*/